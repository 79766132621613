* { margin: 0; padding: 0; box-sizing: border-box; }
body {
    user-select: none;
    background-image: url('https://brainful.s3.amazonaws.com/assets/images/brainfuljs/ripple_background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #232323;
    background-color: navy;
    font-family: Helvetica, Arial;


    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
h1 {background: transparent; color:white;text-align: center; opacity: .75;position:absolute;width:100%;top:1%;}
h2 {background: transparent; color:white;text-align: center; opacity: .75;}
.firebaseWrapper {
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    background-color: #0e0e0ee0;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.signUpText {
    color: #c3c3c3;
    text-align: center;
    font-size: 1.5em;
}

.buttonContainerUpper {
    position: absolute;
    top: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-size: 2em;
}

.buttonContainer {
    position: absolute;
    top: 19%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
}
.buttonLocator {
    background-color: transparent;
    position: absolute;
    opacity: .8;
    font-size: 3em;
    z-index: 10;
    color: white;
}
.topLeftButtonLocator {
    top: 17%;
    right: 64%;
    background-image: url('../public/assets/images/icons/iconWave.png');
    background-size:60%;
    background-position: center;
    background-repeat: no-repeat;
}

.topRightButtonLocator {
    top: 17%;
    left: 64%;
}
.topCenterButtonLocator {
    justify-content: center;
    position: fixed;
    top: 17%;
    left: 51%;
    transform: translate(-1.8em);
    background-image: url('../public/assets/images/icons/iconStop.png');
    background-size:60%;
    background-position: center;
    background-repeat: no-repeat;
}
.appTimer {
    background-color: transparent;
    width: 100%;
    text-align: center;
    height: auto;
    top: 71%;
    position: absolute;
    opacity: .8;
    font-size: 2.5em;
    /*z-index: 10;*/
    color: white;
}
.backgroundCover {
    background-color: #000;
    opacity: .3;
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    z-index: -10;
}
.dotSign {
/*    background-color: rgba(255, 255, 255, 0.95);*/
    position: relative;
    height: 1em;
    width: 1em;
    text-align: center;
    display: block;
    content: "~";
    font-size: 2.5em;
    color: rgba(255, 255, 255, 0.9);
    opacity: 1;
    left:50%;
    top:45%;
    transform: translate(-50%,-50%);
    font-family: Arial;
}
.stopSquare {
    background-color: rgba(255, 255, 255, 0.95);
    background-image: url('../public/assets/images/icons/iconStop.png');;
    background-size:70%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: .8;
}
.startTriangle {
    background-image: url('../public/assets/images/icons/iconPlay.png');
    background-size:60%;
    background-position: 65% 50%;
    background-repeat: no-repeat;
    opacity: .8;
}
.breathWave {
    background-image: url('../public/assets/images/icons/iconWave.png');
    background-size:70%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .8;
}
.iconRefresh {
    background-image: url('../public/assets/images/icons/iconRefresh.png');
    background-size:70%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #04cc3c;
    opacity: .7;
}
.iconHangup {
    background-image: url('../public/assets/images/icons/iconHangup.png');
    background-size:70%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #04cc3c;
    opacity: .8;
}
.shiftRight {
    right:0%;
}
.iconPause {
    background-image: url('../public/assets/images/icons/iconPause.png');
    background-size:45%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #04cc3c;
    opacity: .8;
}
.iconSupport {
    background-image: url('../public/assets/images/icons/iconSupport.png');
    background-size:80%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .6;
}
.iconBrain {
    background-image: url('../public/assets/images/icons/iconBrain.png');
    background-size:90%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .7;
}
.iconEvents {
    background-image: url('../public/assets/images/icons/iconEvent.png');
    background-size:90%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .7;
}
.iconGear {
    background-image: url('../public/assets/images/icons/iconGear.png');
    background-size:80%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .6;
}
.iconBell {
    background-image: url('../public/assets/images/icons/iconBell.png');
    background-size:80%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .6;
}
.circleFilled {
    height: 10em;
    width: 10em;
    background-color: rgba(187, 187, 187, 0.45);
    border-radius: 50%;
    margin: 4em;
}
.circleFilledSmall {
    height: 8em;
    width: 8em;
    background-color: rgba(187, 187, 187, 0.0);
    border-radius: 50%;
    margin: 1em;
    color:white;
    text-align: center;
}
.topBarIcon {
    position:absolute;
    bottom:0%;
    color:white;
    font-size: 2em;
    text-align: center;
    margin: .1em;
}

.centerCircleContainer {
    display: flex;
    justify-content: center;
}
.centerCircle {
    background-size: 38em;
    border-radius: 50%;
    border: .7em solid rgba(255, 255, 255, 0.7);
    display: inline-block;
    width: 38em;
    height: 38em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
}
.centerCircleImg {
    position: absolute;
    width: 16em;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .8;
}
.myButton:hover {
    background-color:#5cbf2a;
}
.myButton:active {
    position:relative;
    top:1px;
}

input { background-color: rgba(187, 187, 187, 0.45); font-size:1em; color: white;border: 0; padding: 10px; width: 90%; margin-right: .5%;text-align: center;border-radius:.3em;}
.typeformButtonDiv {
    position: absolute;
    top:10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.typeformButton {
    text-decoration: none;
    background-color: #3A7685;
/*    background-color: rgba(206, 96, 96, 0.45);*/
    color: rgba(255, 255, 255, 0.74) !important;
    cursor: pointer;
    font-size: 2em;
    height:10%;
    position: relative;
    line-height: 3em;
    padding: 0px 33px;
    border-radius: .5em;
    max-width: 100%;
    margin-top:3em;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    width:40%;
    margin-left: 1em;
    margin-right: 1em;
    text-align: center;
}
#messages2 { list-style-type: none; margin: 0; padding: 0; opacity:0}
#messages2 li { padding: 5px 10px; }
#messages2 li:nth-child(odd) { background: #eee; }
#messages2 { margin-bottom: 40px }

.liveswitch-container {
    position: absolute;
    background-color: white;
    z-index: 10000;
    font-size: 3em;
    display:none;
}
